import {
  Box,
  SvgIcon,
  ThemeProvider,
  Button,
  IconButton,
  Container,
  styled,
} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme } from '@mui/material/styles'
import React from 'react'
import {
  colorPrimary,
  colorPrimaryDark,
  colorSecondary,
  colorTertiary,
  colorSuccess,
  colorError,
} from '../styles/color'
import { QueryClient, QueryClientProvider } from 'react-query'
import DiscordIconSVG from '../icons/discord.svg'
import ZeroKingSVG from '../icons/zeroking.svg'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: colorPrimary,
      dark: colorPrimaryDark,
    },
    secondary: {
      main: colorSecondary,
    },
    success: {
      main: colorSuccess,
    },
    error: {
      main: colorError,
    },
    action: {
      active: colorPrimary,
      focus: colorPrimary,
      selected: colorPrimary,
      hover: colorPrimary,
    },
    background: {
      default: colorTertiary,
    },
  },
  typography: {
    fontFamily: 'KallistoMedium',
    h1: { fontFamily: 'KallistoBold' },
    h2: { fontFamily: 'KallistoBold' },
    h3: { fontFamily: 'KallistoBold' },
    h4: { fontFamily: 'KallistoBold' },
    h5: { fontFamily: 'KallistoBold' },
    h6: { fontFamily: 'KallistoBold' },
    button: { fontFamily: 'KallistoBold' },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: KallistoBold;
          font-weight: normal;
          font-style: normal;
          src: local(kallisto), url('/fonts/kallisto_bold.otf'), format('opentype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: KallistoMedium;
          font-weight: normal;
          font-style: normal;
          src: local(kallisto), url('/fonts/kallisto_medium.otf'), format('opentype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
})

// Create a client
const queryClient = new QueryClient()

type RootElementProps = {
  element: React.ReactNode
}

const createResponsiveMenuButton = (breakPoint: 'sm' | 'md') =>
  styled(Button)(({ theme }) => ({
    '.MuiButton-endIcon': {
      display: 'none',
    },
    [theme.breakpoints.down(breakPoint)]: {
      fontSize: '0',
      '.MuiButton-endIcon': {
        display: 'block',
        margin: 0,
      },
    },
  }))

const ResponsiveMenuButtonSm = createResponsiveMenuButton('sm')
const ResponsiveMenuButtonMd = createResponsiveMenuButton('md')

// Wraps every page in a component
export const RootElement: React.FC<RootElementProps> = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <Box
          width="100%"
          height="100px"
          position={'relative'}
          padding={2}
          zIndex={99}
        >
          <Container>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="right"
              alignItems="center"
              gap={2}
              width="100%"
            >
              <IconButton sx={{}} href="/">
                <SvgIcon titleAccess="Zero King logo">
                  <ZeroKingSVG />
                </SvgIcon>
              </IconButton>

              <ResponsiveMenuButtonSm sx={{ mr: 'auto', fontSize: '8pt' }}>
                <h1>Zero King</h1>
              </ResponsiveMenuButtonSm>

              <ResponsiveMenuButtonMd
                variant="text"
                href="/leaderboards"
                endIcon={<LeaderboardIcon />}
              >
                LeaderBoards
              </ResponsiveMenuButtonMd>
              <ResponsiveMenuButtonMd
                variant="text"
                href="/hall-of-fame"
                endIcon={<EmojiEventsIcon />}
              >
                Hall Of Fame
              </ResponsiveMenuButtonMd>
              <Button
                aria-label="Join Discord"
                color="primary"
                endIcon={
                  <SvgIcon>
                    <DiscordIconSVG />
                  </SvgIcon>
                }
                sx={(theme) => ({
                  [theme.breakpoints.down('md')]: {
                    fontSize: '0',
                    '.MuiButton-endIcon': {
                      margin: 0,
                    },
                  },
                })}
                target="_blank"
                href="https://discord.gg/jBXjs7aFzp"
              >
                Join Discord
              </Button>
            </Box>
          </Container>
        </Box>
        {element}
      </QueryClientProvider>
    </ThemeProvider>
  )
}
